$grau-hell: darkgrey;
$grau-mittel: #393536;
$grau-dunkel: #1b1b1b;

$rundgang-bild-links-oben: "/images/turm_rundgang_009.jpg";
$rundgang-bild-links-unten: "/images/Turm_Location_Nacht.jpg";
$rundgang-bild-rechts-oben: "/images/turm_rundgang_010.jpg";
$rundgang-bild-rechts-unten: "/images/turm_rundgang_008.jpg";

$einmietung-bild-links-oben: "/images/g15752.png";
$einmietung-bild-links-unten: "/images/g6590.png";
$einmietung-bild-rechts-oben: "/images/g28563.png";
$einmietung-bild-rechts-unten: "/images/g10795.png";

*{
  -ms-scroll-chaining: none;
}

#mCSB_2{
  touch-action: cross-slide-x pan-x;
}

.card.outer-card{
  touch-action: cross-slide-x pan-x;
}

.pace{
  z-index: 9999;
}
.pace-progress{
  height: 6px !important;
}

/* ------------------------------------------------------------- */
// -------------- H E R E --------------------------------------
/* ------------------------------------------------------------- */


$hover-opacity: 0.75;
$golden-left: 61.8%;
$golden-right: 38.2%;

$here-header-big: 3em;
$here-header-small: 1.5em;
$here-text: 1.1em;

$padding-between-pictures: 2px;

.here-container{
  display: flex; display: -webkit-flex;
  flex-direction: column; -webkit-flex-direction: column;
  width: 100%;
  height: 100%;
}

@media (orientation: portrait) {
  .here-row{
    display: flex; display: -webkit-flex;
    flex-direction: column; -webkit-flex-direction: column;
    flex: 0 0 90%; -webkit-flex: 0 0 90%;
  }

  .here-cell-left.upper{
    margin-right: 0;
    border-bottom: solid 1px white;
    background-image: url("#{$rundgang-bild-links-oben}");
  }
  .here-cell-left.lower{
    margin-right: 0;
    border-bottom: solid 1px white;
    background-image: url("#{$rundgang-bild-links-unten}");
  }

  .here-row.pad-down{
    padding-bottom: 0;
    border-bottom: solid 1px white;
  }

  .here-cell-content{
    position: absolute;
    top: 0;
    text-align: justify;
    left: 0;
    right: 0;
    margin: 0 5%;
  }

  .here-header{
    margin: 5px 0 0 0;
  }
  .here-text{
    margin: 5px 0 0 0;
  }
}

@media (orientation: landscape) {
  .here-row{
    display: flex; display: -webkit-flex;
    flex-direction: row; -webkit-flex-direction: row;
    flex: 0 0 50%; -webkit-flex: 0 0 50%;
  }

  .here-cell-left.upper{
    margin-right: $padding-between-pictures;
    background-image: url("#{$rundgang-bild-links-oben}");
  }
  .here-cell-left.lower{
    margin-right: $padding-between-pictures;
    background-image: url("#{$rundgang-bild-links-unten}");
  }

  .here-row.pad-down{
    padding-bottom: $padding-between-pictures;
  }

  .here-cell-content{
    position: absolute;
    top: 0;
    text-align: justify;
    left: 0;
    right: 0;
    margin: 0 25%;
  }
}



@media (orientation: portrait) {
  .here-cell{
    position: relative;
    flex: 1 0 0 !important;
    -webkit-flex: 1 !important;
    overflow: scroll!important;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

@media (orientation: landscape) {
  .here-cell{
    position: relative;
  }
}

.here-cell-left{
  flex: 0 0 $golden-left;
  -webkit-flex: 0 0 $golden-left;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.here-cell-rigth{
  flex: 0 0 $golden-right;
  -webkit-flex: 0 0 $golden-right;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.here-cell-rigth.upper{

  background-image: url("#{$rundgang-bild-rechts-oben}");
}
.here-cell-rigth.lower{
  background-image: url("#{$rundgang-bild-rechts-unten}");
}

.here-header{
  text-align: center;
  font-weight: 300;
  margin-top: 1.5em;
  color: white;
}
.here-header.bg{
  font-size: $here-header-big;
}
.here-header.sm{
  font-size: $here-header-small;
}

.here-text{
  color: white;
  font-size: $here-text;
}

.schwarz{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}
.schwarz-in{
  background-color: rgba(0, 0, 0, $hover-opacity);
  animation: 0.5s black-in; -webkik-animation: 0.5s black-in;
  animation-fill-mode: forwards; -webkit-animation-fill-mode: forwards;
  animation-iteration-count: 1; -webkit-animation-iteration-count: 1;
}
.schwarz-out{
  background-color: rgba(0, 0, 0, 0);
  animation: 0.5s black-out; -webkik-animation: 0.5s black-out;
  animation-fill-mode: forwards; -webkit-animation-fill-mode: forwards;
  animation-iteration-count: 1; -webkit-animation-iteration-count: 1;
}

@keyframes black-in {
  from{ background-color: rgba(0, 0, 0, 0); }
  to{ background-color: rgba(0, 0, 0, $hover-opacity); }
}
@keyframes black-out {
  from{ background-color: rgba(0, 0, 0, $hover-opacity); }
  to{ background-color: rgba(0, 0, 0, 0); }
}

/* ------------------------------------------------------------- */
// -------------- I M P R E S S U M ----------------------------
/* ------------------------------------------------------------- */
.imp-col{
  display: flex; display: -webkit-flex;
  flex-direction: column; -webkit-flex-direction: column;
}

@media (orientation: portrait) {
  .imp-row{
    display: flex;  display: -webkit-flex;
    flex-direction: column; -webkit-flex-direction: column;
  }
  .imp-container{
    width: 100%;
    //height: 100%;
    padding: 0 6%;
    padding-top: 7.5%;
  }
}

@media (orientation: landscape) {
  .imp-row{
    display: flex;  display: -webkit-flex;
    flex-direction: row; -webkit-flex-direction: row;
  }

  .imp-container{
    width: 100%;
    //height: 100%;
    padding: 0 6%;
    padding-top: 7.5%;
  }
}

.imp-placeholder{
  flex: 0 0 10%;
  -webkit-flex: 0 0 10%;
}
.imp-content{
  flex: 1 0 0;
  -webkit-flex: 1;
}
.imp-head{
  font-weight: 700;
  font-size: 3em;
  margin-bottom: 2em;
}

/* ------------------------------------------------------------- */
// -------------- E I N M I E T U N G --------------------------
/* ------------------------------------------------------------- */
.left-upper{
  background-image: url("#{$einmietung-bild-links-oben}");
  background-size: cover;

  position:  absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.left-lower{
  background-image: url("#{$einmietung-bild-links-unten}");
  background-size: cover;
}
.right-upper{
  background-image: url("#{$einmietung-bild-rechts-oben}");
  background-size: cover;
}
.right-lower{
  background-image: url("#{$einmietung-bild-rechts-unten}");
  background-size: cover;
}
.rent-container input[type="checkbox"]{
  visibility: hidden;
}
.checkbox {
  width: 20px;
  margin: 20px auto;
  position: relative;
}

.checkbox label {
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -5px;
  left: 0;

  -webkit-box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
  -moz-box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
  background: $grau-dunkel;
}

.checkbox label:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  content: '';
  position: absolute;
  width: 9px;
  height: 5px;
  background: transparent;
  top: 6px;
  left: 5px;
  border: 3px solid white;
  border-top: none;
  border-right: none;

  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.checkbox label:hover::after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  opacity: 0.5;
}

.checkbox input[type=checkbox]:checked + label:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
}

.vam-button{
  text-align: left;
  background-color: $grau-dunkel;
  border: solid 15px $grau-dunkel;
  color: white;
  height: 3em;
  font-weight: 300;
  font-size: 1.25em;
  background-image: url("/images/Formular_Abschicken.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% center;
  /* ONLY FOR WEBKIT
  background-position-x: 100%;
  */
}

.vam-dropdown{
  width: 100%;
  background-color: $grau-dunkel;
  color: white;
  font-weight: 300;
  background-image: url("/images/Formular_Dropdown.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 95%;
  border: solid 5px $grau-dunkel;
}

.rent-container input[type="text"]{
  border-width: 0;
  background-color: $grau-hell;
  height: 2.5em;
  margin-bottom: 1.5em;
  font-size: 1.25em;
  font-weight: 600;
  padding: 0 0.5em;
  outline: none;
  transition: border 0.3s;
}

.rent-container input[type="text"]:focus{
  border-style:solid;
  border-color:#000000;
  border-width: 2px;
}

.rent-container textarea{
  border-style:solid;
  border-color:#000000;
  border-width: 0;
  background-color: $grau-hell;
  margin-bottom: 1.5em;
  font-size: 1.25em;
  font-weight: 600;
  padding: 0.5em;
  outline: none;
  transition: border 0.3s;
}

.rent-container label{
  font-weight: 300;
  font-size: 1.25em;
}

.rent-location{
  margin-bottom: 2em;
}

.rent-location span{
    font-weight: 300;
    font-size: 1.25em;
}

.rent-container textarea:focus{
  border-style:solid;
  border-color:#000000;
  border-width: 2px;
}

.rent-container select{
  height: 2.5em;
  font-size: 1.25em;
}
.swcap>.sweetcaptcha{
  max-width: 100%!important;
}

.flex-col{
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.flex-row{
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
}

@media (orientation: portrait) {
  .rent-row{
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  .rent-container{
    width: 100%;
    //height: 100%;
    padding: 0 6%;
    padding-top: 7.5%;
  }
}

@media (orientation: landscape) {
  .rent-row{
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
  }
  .rent-container{
    width: 100%;
    //height: 100%;
    padding: 0 6%;
    padding-top: 7.5%;
  }
}

.flex{
  flex: 1 0 0;
  -webkit-flex: 1;
}

.flex-s-auto{
  flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
}

.rent-placeholder{
  flex: 0 0 12.5%;
  -webkit-flex: 0 0 12.5%;
}
.rent-placeholder-half{
  flex: 0 0 7.5%;
  -webkit-flex: 0 0 7.5%;
}

.rent-header{
  width: 100%;
  margin-bottom: 5em;
}
.rent-head{
  font-weight: 700;
  font-size: 3em;
}

.rent-form>div{
  margin: 0 3em 0 0;
}

.rent-location>div{
  flex: 1 0 0;
  -webkit-flex: 1;
  text-align: left;
  font-weight: bold;
}

.rent-img-container{
  width: 100%;
  position: relative;
  background-color: white;
  //height: 10em;
  margin-top: 5px;
  border: solid 1px lightgray;
}

.rent-img-container:before{
  content: "";
  display: block;
  padding-top: 100%; 	/* initial ratio of 1:1*/
}

/* ------------------------------------------------------------- */
// -------------- B E W E R B E N ------------------------------
/* ------------------------------------------------------------- */

.apply-container input[type="text"]{
  border-width: 0;
  background-color: $grau-hell;
  height: 2.5em;
  margin-bottom: 1.5em;
  font-size: 1.25em;
  font-weight: 600;
  padding: 0 0.5em;
  outline: none;
  transition: border 0.3s;
}

.apply-container input[type="text"]:focus{
  border-style:solid;
  border-color:#000000;
  border-width: 2px;
}

.apply-container textarea{
  border-style:solid;
  border-color:#000000;
  border-width: 0;
  background-color: $grau-hell;
  margin-bottom: 1.5em;
  font-size: 1.25em;
  font-weight: 600;
  padding: 0.5em;
  outline: none;
  transition: border 0.3s;
}
.apply-container textarea:focus{
  border-style:solid;
  border-color:#000000;
  border-width: 2px;
}

.apply-container select{
  height: 2.5em;
  font-size: 1.25em;
  font-weight: 300;
  background-color: $grau-dunkel;
}

.apply-container label{
    font-weight: 300;
    font-size: 1.25em;
}

@media (orientation: portrait) {
  .apply-row{
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
  }

  .apply-container{
    width: 100%;
    //height: 100%;
    padding: 0 6%;
    padding-top: 7.5%;
  }
}

@media (orientation: landscape) {
  .apply-row{
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
  }

  .apply-container{
    width: 100%;
    //height: 100%;
    padding: 0 6%;
    padding-top: 7.5%;
  }
}

/* ------------------------------------------------------------- */
// -------------- E V E N T - C A R D S ------------------------
/* ------------------------------------------------------------- */

.scroll-outer-container-cards{
  height: 100%;
}

.scroll-inner-container-cards{
  width: 100%;
  height: 100%;
  /* old with scroll on bottom
  padding-bottom: 3em;
  */
  padding-bottom: 2.5em;
}

.flex-row{
  display: flex;
  flex-direction: row;
  display: -webkit-flex;
  -webkit-flex-direction: row;
}

.card{
  position: relative;
  overflow:hidden;
}

.card-content{
  /* position:absolute; */

  color: white;
  width: 100%;
  height: 100%;

  margin-top: 2.5em;
  /* old with scroll on bottom
  padding-bottom: 45px;
  */
  padding-bottom: 2.5em;
  padding-left: 1.25em;
  padding-right: 1.25em;
}

.card-content-left{
/*  flex: 1;
-webkit-flex: 1;*/
  height:100%;

  /*
  overflow: auto;
  */
  padding-left: 10px;
  padding-right: 25px;
  position: relative;

  background-color: #1b1b1b;
  color: #fff;
}

.card-content-right{
/*  flex: 1 0 auto;
  -webkit-flex: 1 0 auto;*/
  height:100%;

  padding:0;

  color: #fff;
  background-color: #1b1b1b;
}

/* Innerer Card-Content (Text etc.)*/
.card-content-padding>div{
  margin: 0 0 2em 0;
}
.event-text-title{
  font-size: 3em;
  font-weight: 700;
  margin-bottom: 0;
}
.event-text-date{
  margin-top: 0;
  font-size: 1.75em;
  font-weight: 300;
}
.event-text-description{
  margin-top: 3em;
  font-size: 1.25em;
}
.event-text-infos{
  font-size: 1.5em;
  font-weight: 300;
}
.event-text-note{
  margin-top: 3em;
  font-size: 1.25em;
  font-weight: 700;
}

.active-card{
  color: #000;
  background-color: white;
}

.shadow{
  width:100%; height: 100%; position:absolute; bottom:0; left:0; pointer-events: none;
}
.shadow-white{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&amp;0+81,1+100 */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjgxJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 81%, rgba(255,255,255,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(81%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 81%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 81%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 81%,rgba(255,255,255,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 81%,rgba(255,255,255,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-8 */
}
.shadow-black{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,000000+100&amp;0+81,1+100 */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjgxJSIgc3RvcC1jb2xvcj0iIzMwMzAzMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(48,48,48,0) 81%, rgba(0,0,0,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(81%,rgba(48,48,48,0)), color-stop(100%,rgba(0,0,0,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(48,48,48,0) 81%,rgba(0,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(48,48,48,0) 81%,rgba(0,0,0,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(48,48,48,0) 81%,rgba(0,0,0,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(48,48,48,0) 81%,rgba(0,0,0,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-8 */

}

/* ------------------------------------------------------------- */
// -------------- E V E N T - T I L E S ------------------------
/* ------------------------------------------------------------- */

.tile-active .divider {
  background-color: darkorange;
  color: black;
  height: 6px;
}

.eventTitle{
  text-align: center;
  margin-bottom: 1.25em;
  padding-left: 20px;
  padding-right: 20px;
}

.first-tile .eventTitle{
  margin-left: -5em;
}

.eventDate{

}
.first-tile .eventDate{
  margin-left: -5em;
}
.eventType{

}
.first-tile .eventType{
  margin-left: -5em;
}
.divider{
  width: 100%;
  height: 5.5px;

  background-color: white;
  margin-top:2px;
  margin-bottom: 1px;
}
.first-tile .divider{
  margin-left: -5em;
}

//Animation
.white-in{
  animation-duration: 0.75s; -webkit-animation-duration: 0.75s;
  animation-fill-mode: forwards; -webkit-animation-fill-mode: forwards;
  animation-iteration-count: 1; -webkit-animation-iteration-count: 1;
  animation-name: white-opac-in; -webkit-animation-name: white-opac-in;
}

.white-out{
  animation-duration: 0.75s; -webkit-animation-duration: 0.75s;
  animation-fill-mode: forwards; -webkit-animation-fill-mode: forwards;
  animation-iteration-count: 1; -webkit-animation-iteration-count: 1;
  animation-name: white-opac-out; -webkit-animation-name: white-opac-out;
}

@keyframes white-opac-in {
  from{
   background-color: rgba(255,255,255,0);
  }
  to{
   background-color: rgba(255,255,255,1);
  }
}

@keyframes white-opac-out {
  from{
    background-color: rgba(255,255,255,1);
  }
  to{
    background-color: rgba(255,255,255,0);
  }
}